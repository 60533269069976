<template>
  <section>
    <div class="content-header">
      <h2>Journal articles</h2>
    </div>
    <div class="content-wrapper">
      <ul>
        <li class="pb-2"><a href="https://cjc-rcc.ucalgary.ca/article/view/61246/53346" target="_blank">Affirmative Career Counselling with Transgender and Gender Nonconforming Clients: A Social Justice Perspective</a> </li>
        <li class="pb-2"><a href="https://www.tandfonline.com/doi/abs/10.1080/03069885.2018.1513451?journalCode=cbjg20" target="_blank">The broader aims of career development: Mental health, wellbeing and work</a></li>
        <li class="pb-2"><a href="https://journals.sagepub.com/doi/10.1177/070674371105600203" target="_blank">Rethinking Resilience from Indigenous Perspectives</a></li>
        <li class="pb-2"><a href="https://www.tandfonline.com/doi/full/10.1080/07294360.2010.502220" target="_blank">University of Technology Sydney’s Work-Ready Project </a></li>
        <li class="pb-2"><a href="https://cjcd-rcdc.ceric.ca/index.php/cjcd/article/view/199" target="_blank">Intersections of Career Development and Post-Secondary Education for Indigenous Students: Exploring the Integrity of Social and Cultural Issues</a></li>
      </ul>
    </div>
  </section>
</template>

<script>
// import Accordion from '../components/Accordion.vue'
// import SidebarMenu from '../components/SidebarMenu.vue'
// import {
//   Hooper,
//   Slide,
//   Navigation as HooperNavigation,
//   Pagination as HooperPagination
// } from 'hooper'
// import 'hooper/dist/hooper.css'

export default {
  name: '02',
  components: {
    // SidebarMenu,
    // Accordion,
    // Hooper,
    // Slide,
    // HooperNavigation,
    // HooperPagination
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },

  mounted () {
  }
}
</script>

<style>
</style>
